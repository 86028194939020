document.addEventListener('DOMContentLoaded', () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  const todayDate = `${yyyy}-${mm}-${dd}`;

  const dateStartInput = document.getElementById('date_start');
  const dateDueInput = document.getElementById('date_due');
  const durationInput = document.getElementById('duration');

  if (dateStartInput) {
    dateStartInput.addEventListener('change', (event) => {
      const dateStart = new Date(event.target.value);
      if (dateStart.getTime()) {
        // Set the due date to exactly one year after the start date
        const dateDue = new Date(dateStart);
        dateDue.setFullYear(dateStart.getFullYear() + 1);

        // Format date as YYYY-MM-DD for the input field
        const year = dateDue.getFullYear();
        const month = String(dateDue.getMonth() + 1).padStart(2, '0');
        const day = String(dateDue.getDate()).padStart(2, '0');
        dateDueInput.value = `${year}-${month}-${day}`;

        // Now calculate the exact duration in months, considering days
        const exactMonths = calculateExactMonths(dateStart, dateDue);
        durationInput.value = exactMonths;
      } else {
        dateDueInput.value = '';
        durationInput.value = '';
      }
    });
  }

  dateDueInput.addEventListener('change', (event) => {
    const dateStart = new Date(dateStartInput.value);
    const dateDue = new Date(event.target.value);
  
    const existingError = document.getElementById('date-error');
    if (existingError) {
      existingError.remove();
    }
    dateDueInput.classList.remove('is-invalid');
    durationInput.classList.remove('is-invalid');
  
    if (dateStart.getTime() && dateDue.getTime() && dateDue >= dateStart) {
      // Calculate the exact duration in months
      const totalMonths = calculateExactMonths(dateStart, dateDue);
      durationInput.value = totalMonths;
  
      if (totalMonths >= 12) {
        durationInput.value = totalMonths;
      } else {
        // Create a new span element for the error message for date due input field
        const errorSpan = document.createElement('span');
        errorSpan.id = 'date-error';
        errorSpan.className = 'text-danger small';
        errorSpan.textContent = 'Please ensure a duration of at least 12 months and select the end date accordingly';
  
        // Insert the error message after the dateDue input field
        dateDueInput.parentNode.insertBefore(errorSpan, dateDueInput.nextSibling);
        dateDueInput.classList.add('is-invalid');
        durationInput.classList.add('is-invalid');
      }
    } else {
      durationInput.value = '';
    }
  });

  function calculateExactMonths(startDate, endDate) {
    let months;
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();
  
    if (endDate.getDate() < startDate.getDate()) {
      months--;
    }
  
    return months <= 0 ? 0 : months;
  }

});