document.addEventListener('DOMContentLoaded', () => {

  if (sessionStorage.getItem('showModal') === 'true') {
    const modal = document.getElementById('myModal');
    modal.style.display = 'block';

    sessionStorage.removeItem('showModal');

    setTimeout(() => {
      modal.style.display = 'none';
    }, 2500);
  }

  $('#save-button').on('click', function(event) {
    sessionStorage.setItem('showModal', 'true');
    document.forms[0].submit();
  });

  $('#customer-approval-submit').on('click', function(event) {
    sessionStorage.setItem('showModal', 'true');
  });

  $('#customer-approval-save').on('click', function(event) {
    sessionStorage.setItem('showModal', 'true');
  });
});
