document.addEventListener("DOMContentLoaded", function() {
  const checkAllColumns = document.querySelectorAll('.check-all-column');
  function updateCheckAllState() {
      checkAllColumns.forEach(checkAll => {
          const columnIndex = checkAll.getAttribute('data-column-index');
          const checkboxes = document.querySelectorAll(`.column-${columnIndex}`);
          const allChecked = Array.from(checkboxes).every(checkbox => checkbox.checked || checkbox.disabled);
          checkAll.checked = allChecked;
      });
  }

  updateCheckAllState();

  checkAllColumns.forEach(checkAll => {
      checkAll.addEventListener('change', function() {
          const columnIndex = this.getAttribute('data-column-index');
          const checkboxes = document.querySelectorAll(`.column-${columnIndex}`);
          checkboxes.forEach(checkbox => {
              if (!checkbox.disabled) {
                  checkbox.checked = this.checked;
              }
          });
      });
  });

  const allCheckboxes = document.querySelectorAll('.permission_check_box');
  allCheckboxes.forEach(checkbox => {
      checkbox.addEventListener('change', updateCheckAllState);
  });
});